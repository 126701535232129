<template>
  <v-card
    id="cost-card"
    color="transparent"
    elevation="0"
    class="mx-auto my-12 mobile-text"
    :max-width="640"
  >
    <div class="d-flex justify-space-between secondaryText--text mb-1">
      <div>{{ $t('subscription.amount.max') }}</div>
      <div>{{ $n(investorSubscriptionInformation.spendableCashBalance, 'float') }} €</div>
    </div>
    <div class="d-flex justify-space-between secondaryText--text mb-1">
      <div class="d-flex">
        <div class="defaultText--text mr-2">{{ $t('subscription.amount.subscriptionAmount') }}</div>
        <div>1 Token = {{ $n(investorSubscriptionInformation.tokenPrice, 'integer') }} Euro</div>
      </div>
      <div class="defaultText--text">{{ $n(subscriptionAmount, 'integer') }} €</div>
    </div>
    <div class="d-flex justify-space-between secondaryText--text">
      <div class="d-flex">
        <div class="mr-2">{{ $t('subscription.amount.debitAmount') }}</div>
        <div>
          <v-tooltip top color="backgroundLight" :max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="backgroundLight"
                class="info-chip"
                small
              >i</v-chip>
            </template>
            <span>{{ $t('subscription.amount.tooltipMessage') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div>{{ $n(debitAmount, 'float')}} €</div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CostCard',
  computed: {
    ...mapGetters('subscription', [
      'investorSubscriptionInformation',
      'subscriptionAmount',
      'debitAmount',
    ]),
  },
};
</script>

<style lang="scss">
.info-chip {
  padding-left: 7px !important;
  padding-right: 7px !important;
  height: 16px !important;
}

@media (max-width: 600px) {
  .mobile-text {
    font-size: 12px !important;
  }
}
</style>
